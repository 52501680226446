// stecy
// Copyright (C) 2024 Oscar

$border: #ffafcc;

$header-title: white;
$header-title-shadow-1: #d6004f;
$header-title-shadow-2: #006cd1;
$header-title-bloom: #bde0fe;
$header-links: white;
$header-links-shadow: #ff458c;
$header-links-background: #ff458c;

$about-text: #ffe2ed;
$about-text-shadow: #d6004f;
$about-text-shadow-1: #d6004f;
$about-text-shadow-2: #006cd1;
$about-text-bloom: #ffafcc;
$about-link: #1ea888;
$about-background-gradient-1: #ffc8dd;
$about-background-gradient-2: #cdb4db;

$ribbon-text: white;
$ribbon-text-shadow: #d6004f;
$ribbon-background: #000000;

$contact-content-border: #5c77ff;
$contact-content-background: #000080;
$contact-background-gradient-1: #191c7e;
$contact-background-gradient-2: #17043f;

$footer-border: #afcfff;
$footer-text: white;
$footer-background-overlay: #000000;
$footer-background-gradient-1: #0400fa;
$footer-background-gradient-2: #29a378;

%content {
    content-visibility: auto;
    min-width: 100%;
    min-height: min-content;
    box-sizing: border-box;
    padding: 4rem 0.5rem;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
    font-family: "Fira Sans", sans-serif;
    border-top: dashed 0.5rem $border;
}

%appearing {
    will-change: opacity;
    transition: opacity 150ms ease-in-out;
}

.font_size_hover {
    will-change: font-size;
    transition: font-size 250ms ease-in-out;
    &:hover {
        font-size: 110%;
    }
}

.scale_hover {
    will-change: transform;
    transition: transform 250ms ease-in-out;
    &:hover {
        transform: scale(105%);
    }
}

@mixin maximize() {
    position: relative;
    top: 0px;
    left: 0px;
    min-height: 75vh;
    min-width: 100%;
    @media (min-width: 640px) {
        min-height: 100vh;
    }
}

@mixin glass($blur: 1px, $saturate: 100%) {
    backdrop-filter: blur($blur) saturate($saturate);
    -webkit-backdrop-filter: blur($blur) saturate($saturate);
}
