// stecy
// Copyright (C) 2024 Oscar

@import "./consts";

#about {
    min-height: 100vh;
    color: $about-text;
    &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background: radial-gradient($about-background-gradient-1, $about-background-gradient-2);
    }
    h1 {
        @extend .scale_hover;
        margin: 2rem;
        font-size: 7.5rem;
        text-shadow:
            0.2rem 0.2rem $about-text-shadow,
            0 0 4px $about-text-bloom,
            0 0 4px white,
            0 0 4px black;
        font-family: "Cookie";
        will-change: opacity;
        transition: opacity 250ms ease-in-out;
        @media (max-width: 640px) {
            font-size: 5rem;
        }
    }
    h2 {
        text-shadow: 0 0 2px black;
    }
    h3,
    p {
        color: $about-text;
    }
    a {
        color: $about-link;
    }
}

a {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
    text-decoration-skip: none;
    &:hover {
        text-decoration-thickness: 0.4rem;
    }
}

#intro {
    @include maximize();
    display: flex;
    justify-content: left;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: 640px) {
        align-items: baseline;
    }
    #intro_background {
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url("../../assets/images/portrait.png?as=avif&quality=75");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 0%;
        @media (max-width: 640px) {
            background-position: 100% 100%;
        }
    }
    #intro_title {
        margin: 4rem;
        color: rgba($color: $about-text, $alpha: 1);
        text-align: justify;
        text-shadow:
            0.35rem 0.35rem $about-text-shadow-1,
            -0.2rem -0.2rem $about-text-shadow-2,
            0 0 1rem $about-text-bloom,
            0 0 3px black,
            0 0 4px white;
        font-size: 15rem;
        font-family: "Cookie";
        @media (max-width: 640px) {
            margin: 2rem;
            font-size: 6rem;
        }
        @media (max-height: 640px) {
            font-size: 6rem;
        }
    }
}

#first_glance {
    @extend %content;
    @extend %appearing;
    @include glass(24px, 125%);
    background-color: rgba($color: $ribbon-background, $alpha: 0.2);
    h2 {
        margin: 2rem;
        color: $ribbon-text;
        text-align: center;
        text-shadow:
            0.2rem 0.2rem $ribbon-text-shadow,
            0 0 2px black,
            0 0 3px white;
        font-size: 8rem;
        @media (max-width: 640px) {
            margin: 0 1rem 0 1rem;
            text-align: center;
            font-size: 5rem;
        }
        @media (max-height: 640px) {
            font-size: 5rem;
        }
    }
}

#contact {
    @extend %content;
    @extend %appearing;
    @include glass(32px, 100%);
    background-color: rgba($color: $contact-background-gradient-1, $alpha: 0.2);
    text-align: center;
    h1 {
        margin-bottom: 0.5rem;
    }
    h2 {
        margin-top: 0.5rem;
        font-size: 2.5rem;
    }
    div {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: center;
        a {
            @include glass(32px, 100%);
            @extend .scale_hover;
            text-decoration: none;
            margin: 1.5rem;
            padding: 1.5rem;
            text-shadow: 0 0 1.5px $about-text-shadow;
            background-color: rgba($color: white, $alpha: 0.2);
            text-align: left;
            border: dashed 0.35rem #f5005a;
            border-radius: 2rem;
            h3 {
                margin: 1rem;
                color: $about-text;
                text-shadow:
                    0.2rem 0.2rem $about-text-shadow,
                    0 0 4px black;
                font-size: 5rem;
                @media (max-width: 640px) {
                    margin: 0.75rem;
                    font-size: 4rem;
                }
            }
            p {
                margin: 1rem;
                font-size: 1.25rem;
                font-family: "Helvetica";
                @media (max-width: 640px) {
                    margin: 0.75rem;
                    font-size: 1rem;
                }
            }
        }
    }
}

footer {
    @extend %content;
    @include glass(8px, 125%);
    position: relative;
    min-height: unset;
    max-height: min-content;
    padding: 4rem;
    color: $footer-text;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    border-top: 0.35rem solid $footer-border;
    background: rgba($color: #ff146a, $alpha: 0.05);
    h1 {
        @extend .scale_hover;
        margin: 0.25rem;
        font-size: 5.5rem;
        font-family: "Cookie";
        text-shadow:
            0.25rem 0.25rem $about-text-shadow-1,
            -0.2rem -0.2rem $about-text-shadow-2,
            0 0 1rem $about-text-bloom,
            0 0 3px black,
            0 0 4px white;
    }
    #footerNotice {
        opacity: 0.75;
        font-size: 1.5rem;
        line-height: 0;
        text-shadow: 0 0 4px black;
    }
}
