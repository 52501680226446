footer, #contact, #first_glance {
  content-visibility: auto;
  box-sizing: border-box;
  padding: 4rem .5rem;
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  border-top: .5rem dashed #ffafcc;
  min-width: 100%;
  min-height: min-content;
  font-family: Fira Sans, sans-serif;
}

#contact, #first_glance {
  will-change: opacity;
  transition: opacity .15s ease-in-out;
}

.font_size_hover {
  will-change: font-size;
  transition: font-size .25s ease-in-out;
}

.font_size_hover:hover {
  font-size: 110%;
}

.scale_hover, footer h1, #contact div a, #about h1 {
  will-change: transform;
  transition: transform .25s ease-in-out;
}

.scale_hover:hover, footer h1:hover, #contact div a:hover, #about h1:hover {
  transform: scale(1.05);
}

#about {
  color: #ffe2ed;
  min-height: 100vh;
}

#about:before {
  content: "";
  background: radial-gradient(#ffc8dd, #cdb4db);
  width: 100%;
  height: 100%;
  position: fixed;
}

#about h1 {
  text-shadow: .2rem .2rem #d6004f, 0 0 4px #ffafcc, 0 0 4px #fff, 0 0 4px #000;
  will-change: opacity;
  margin: 2rem;
  font-family: Cookie;
  font-size: 7.5rem;
  transition: opacity .25s ease-in-out;
}

@media (width <= 640px) {
  #about h1 {
    font-size: 5rem;
  }
}

#about h2 {
  text-shadow: 0 0 2px #000;
}

#about h3, #about p {
  color: #ffe2ed;
}

#about a {
  color: #1ea888;
}

a {
  text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline .2rem;
}

a:hover {
  text-decoration-thickness: .4rem;
}

#intro {
  box-sizing: border-box;
  justify-content: left;
  align-items: center;
  min-width: 100%;
  min-height: 75vh;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (width >= 640px) {
  #intro {
    min-height: 100vh;
  }
}

@media (width <= 640px) {
  #intro {
    align-items: baseline;
  }
}

#intro #intro_background {
  z-index: 0;
  background-image: url("portrait.791afece.avif");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

@media (width <= 640px) {
  #intro #intro_background {
    background-position: 100% 100%;
  }
}

#intro #intro_title {
  color: #ffe2ed;
  text-align: justify;
  text-shadow: .35rem .35rem #d6004f, -.2rem -.2rem #006cd1, 0 0 1rem #ffafcc, 0 0 3px #000, 0 0 4px #fff;
  margin: 4rem;
  font-family: Cookie;
  font-size: 15rem;
}

@media (width <= 640px) {
  #intro #intro_title {
    margin: 2rem;
    font-size: 6rem;
  }
}

@media (height <= 640px) {
  #intro #intro_title {
    font-size: 6rem;
  }
}

#first_glance {
  -webkit-backdrop-filter: blur(24px) saturate(125%);
  background-color: #0003;
}

#first_glance h2 {
  color: #fff;
  text-align: center;
  text-shadow: .2rem .2rem #d6004f, 0 0 2px #000, 0 0 3px #fff;
  margin: 2rem;
  font-size: 8rem;
}

@media (width <= 640px) {
  #first_glance h2 {
    text-align: center;
    margin: 0 1rem;
    font-size: 5rem;
  }
}

@media (height <= 640px) {
  #first_glance h2 {
    font-size: 5rem;
  }
}

#contact {
  -webkit-backdrop-filter: blur(32px) saturate();
  text-align: center;
  background-color: #191c7e33;
}

#contact h1 {
  margin-bottom: .5rem;
}

#contact h2 {
  margin-top: .5rem;
  font-size: 2.5rem;
}

#contact div {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  display: flex;
}

#contact div a {
  -webkit-backdrop-filter: blur(32px) saturate();
  text-shadow: 0 0 1.5px #d6004f;
  text-align: left;
  background-color: #fff3;
  border: .35rem dashed #f5005a;
  border-radius: 2rem;
  margin: 1.5rem;
  padding: 1.5rem;
  text-decoration: none;
}

#contact div a h3 {
  color: #ffe2ed;
  text-shadow: .2rem .2rem #d6004f, 0 0 4px #000;
  margin: 1rem;
  font-size: 5rem;
}

@media (width <= 640px) {
  #contact div a h3 {
    margin: .75rem;
    font-size: 4rem;
  }
}

#contact div a p {
  margin: 1rem;
  font-family: Helvetica;
  font-size: 1.25rem;
}

@media (width <= 640px) {
  #contact div a p {
    margin: .75rem;
    font-size: 1rem;
  }
}

footer {
  -webkit-backdrop-filter: blur(8px) saturate(125%);
  min-height: unset;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  background: #ff146a0d;
  border-top: .35rem solid #afcfff;
  align-items: center;
  max-height: min-content;
  padding: 4rem;
  position: relative;
}

footer h1 {
  text-shadow: .25rem .25rem #d6004f, -.2rem -.2rem #006cd1, 0 0 1rem #ffafcc, 0 0 3px #000, 0 0 4px #fff;
  margin: .25rem;
  font-family: Cookie;
  font-size: 5.5rem;
}

footer #footerNotice {
  opacity: .75;
  text-shadow: 0 0 4px #000;
  font-size: 1.5rem;
  line-height: 0;
}
/*# sourceMappingURL=index.3f96442a.css.map */
